import React, { useState } from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { Modal, Carousel } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Cart from '../Cart/Cart';
const Topimages = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
  const handleImageError= (event) => {
    event.target.src ="https://hatimi.s3.amazonaws.com/frontendpropertyImages/Untitled-1+1.jpg"
  }

  return (
    <div className="row  mx-5 px-5 py-3 mobileresponsive topimages">
        <div className=" py-3 px-0  ">
          <div className="d-flex justify-content-between">
          <h2 className="text-gold w-25 mb-0"> {props.room_type}</h2>
          {/* <Cart/> */}
          </div>
          
          <p className="text-white d-flex align-items-center ">
            <p> {props.property_name}</p>{" "}
            <p className="px-2 d-flex align-items-center m-0">
              {<FaLocationDot />} {props.property_city}
            </p>
          </p>
        </div>
     
       <div className='row'>
       <div className="col-md-6 ps-0 pe-2">
          {/* Left side - One Image */}

          <div>
            <img
              src={props.room_images}
              onError={handleImageError}
              alt="Main Product"
              className="img-fluid w-100 rounded mb-2"
            />
          </div>
        </div>
        <div className="col-md-6 ps-2 pe-0">
          {/* Right side - Four Images */}
          <div className="row ">
          <div className="col-sm-6 mb-3 ">
                <img src={props.room_image?props.room_image:""} onError={handleImageError} className="img-fluid w-100 rounded" />
              </div>
              <div className="col-sm-6 mb-3 ">
                <img src={props.room_imag?props.room_imag:""} onError={handleImageError} className="img-fluid w-100 rounded" />
              </div>
              <div className="col-sm-6 mb-3 ">
                <img src={props.room_ima?props.room_ima:""} onError={handleImageError} className="img-fluid w-100 rounded" />
              </div>
             
              <div className=" col-sm-6 rounded position-relative" onClick={handleShow}>
              <div className=" mb-3 ">
                <img src={props.room_im?props.room_im:""} onError={handleImageError} className="img-fluid w-100 rounded" />
              </div>
          <div className="booked-overlay d-flex align-items-center justify-content-center" >
            <p className='cursor-pointer text-white'>View all</p>
          
          </div>
         
             </div>

             <Modal show={show} onHide={handleClose} centered >
        
        <Modal.Header >
       
          <Modal.Title><h3>Rooms Gallery</h3></Modal.Title>
          <IoClose
                className="text-white fs-3 cursor-pointer" // Added "text-white" class here
                onClick={handleClose}
                aria-label="Close"
              />
        </Modal.Header>
        <Modal.Body className='d-flex flex-wrap gap-3 overflow-auto roomscrollbar '>
  {/* Map through your images to create Carousel.Items */}
  {props.roomImages && props.roomImages.map((image, index) => (
  <img
    key={index}
    src={image?image:""}
    onError={handleImageError}
    className="d-block imagegallery rounded"
    alt={`Gallery ${index + 1}`}
  />
))}

</Modal.Body>

        <Modal.Footer className="border-0 ">
        {/* <button
                className="btn-rounded pX-3 py-2 btn-gold text-black"
                type="button"
              >
                <NavLink
                  className="nav-link"
                  to={`/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}`}
                >
                 View Detail
                </NavLink>
              </button> */}
                  
                </Modal.Footer>
      </Modal>
          </div>
        </div>
       </div>
      </div>
  )
}

export default Topimages