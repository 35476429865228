import React from "react";
import { NavLink } from "react-router-dom";

const EventCard = (props) => {
  return (
    <>
      <div className="col-md-7">
        <div className="services-content text-white d-flex flex-column">
          <div className="d-flex justify-content-start align-items-start mt-4">
            <img
              src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/line.svg"
              className="linehome mx-3 pt-4"
              alt="image"
            />
            <h2 className="text-gold fs-1 m-0">{props.title}</h2>
          </div>
          {/* <p className="fs-6 services-description mobileresponsive">
            {props.tagline}
          </p> */}
          <p className="fs-6 services-description mobileresponsive">
            {props.description}
          </p>
          <div className="services-description mobileresponsive">
            <NavLink to="/contact" className="btn eg-btn btn mt-3">
              {/* View More */} Contact Us
            </NavLink>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <img
        
          src={props.imageUrl}
          className="d-block w-100 rounded my-3 "
          alt=""
          style={{
            height: "280px",
            width: "250px",
            objectFit: "cover",
            transition: 'height 0.3s ease, width 0.3s ease'
          }}
          onError={(e) => {
            e.target.src = "/assets/noimage.png";
            e.target.alt = "No Image";
          }}
        />
      </div>
    </>
  );
};

export default EventCard;
