import React from 'react'
import { FaBed, FaCar, FaCat, FaStar } from "react-icons/fa";
import { MdHotTub } from "react-icons/md";
import { TbListNumbers } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { FaUtensils, FaSnowflake, FaTv, FaWifi, FaSun } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  FaBroom,
  FaSprayCan,
  FaFireExtinguisher,
  FaExclamationTriangle,
} from "react-icons/fa"; // Import necessary icons
import { IoMdResize } from 'react-icons/io';
import { FaMaximize } from 'react-icons/fa6';

const Product_Description = (props) => {
  if (!Array.isArray(props.amenities)) {
    return <p>No amenities available</p>; // Or handle this scenario appropriately
  }
    const reviewItems = [
        { name: "Amenities", rating: 4.9 }, // Example ratings (you can adjust these)
        { name: "Communication", rating: 4.8 },
        { name: "Value for Money", rating: 4.9 },
        { name: "Hygiene", rating: 5 },
        { name: "Location of Property", rating: 4.9 },
      ];
    
      const renderStars = (rating) => {
        const starArray = [];
        for (let i = 1; i <= 5; i++) {
          starArray.push(
            <FaStar
              key={i}
              className={i <= rating ? 'text-gold' : 'text-gray'} // Assuming you have a CSS class for gray stars
              style={{ marginRight: '5px' }}
            />
          );
        }
        return starArray;
      };
    
      const renderStarsAndRating = (rating) => {
        return (
          <div className="d-flex align-items-center">
            {renderStars(rating)}
            <p className="text-white">{rating.toFixed(1)}</p>
          </div>
        );
      };
      const reviews = [
        {
          id: 1,
          userName: "John Doe",
          date: "11-10-2021",
          userProfileIcon: "https://via.placeholder.com/50", // Replace with actual image URL
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et justo at ante malesuada ultrices.",
        },
        {
          id: 2,
          userName: "Jane Smith",
          date: "11-10-2021",
          userProfileIcon: "https://via.placeholder.com/50", // Replace with actual image URL
          description:
            "Vestibulum et nisi vitae tortor varius eleifend. Proin non bibendum magna, sit amet congue augue.",
        },
        // Add more reviews as needed
      ];
    
      const amenityIcons = {
        Kitchen: FaUtensils,
        "Air Conditioner": FaSnowflake,
        Washer: AiOutlineCheckCircle,
        "Television with Netflix": FaTv,
        "Free Wireless Internet": FaWifi,
        "Balcony or Patio": FaSun,
        // Add more amenities and icons as needed
      };
    
      const amenities = Object.keys(amenityIcons);
    
      const renderAmenitiesGroup = (startIndex) => {
        return amenities.slice(startIndex, startIndex + 3).map((amenity, index) => (
          <div className="col-12" key={index}>
            <p>
              {React.createElement(amenityIcons[amenity], {
                size: 20,
                style: { marginRight: "5px" },
              })}
              {amenity}
            </p>
          </div>
        ));
      };
    
      const rules = [
        "No smoking allowed inside the premises",
        "Pets are not allowed",
        "Keep noise levels down after 10 PM",
        "Follow the designated parking areas",
        // Add more rules as needed
      ];

  return (
    <div className="col-lg-7 pe-2 ps-0">
            <div className="icon-row text-gold d-flex justify-content-between gap-2">
              <div className="px-3 py-4 d-flex flex-column justify-content-center align-items-center gap-3 product_detail_icon_bg rounded w-25 mobilewidth ">
                <FaBed className=" icon fs-3  " />
                {/* <img src="https://hatimi.s3.us-east-1.amazonaws.com/icon/1708080300585_icon_cloud-svgrepo-com%20%281%29.svg" alt="" className='text-white w-25 ' /> */}
                <p className="text-gold">Bed Size : {props.bed_size}</p>
              </div>
              <div className="px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 product_detail_icon_bg rounded w-25 mobilewidth ">
              <TbListNumbers className="icon fs-3" />
                <p>Room No : {props.room_number}</p>
              </div>

              <div className="px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 product_detail_icon_bg rounded w-25 mobilewidth ">
              <IoMdResize className="icon fs-3"/>
                <p>Room Size : {props.room_size}</p>
              </div>

              <div className="px-2 py-4 d-flex flex-column justify-content-center align-items-center gap-3 product_detail_icon_bg rounded w-25 mobilewidth ">
              <FaMaximize className="icon fs-3" />
                <p>Max Guest : {props.max_guest_occupancy}</p>
              </div>
            </div>

            {/* Product Description */}
            <div className="product-description text-white py-5">
              <p className='py-2'>Room Description</p>
              <p>
               {props.room_description}
              </p>
            </div>


   

            <div className="col-6 booking_amenities text-white">
        <p>Offered Amenities</p>
        <div className="row my-2">
          <div className="col-6">
            <ul className="amenities-list">
              {props.amenities.slice(0, 3).map((amenity, index) => (
                 <li
                 key={index}
                 className="amenity-item gap-2 d-flex align-items-center"
               >
                 {/* Assuming amenity_icon should be used here */}
                 {amenity.amenity_icon && (
                   <img
                     src={amenity.amenity_icon}
                     alt={amenity.amenity_name}
                     className="icon property_icons text-white roomaminities mb-2"
                     // Add necessary styles for the icon
                   />
                 )}
                 <span>{amenity.amenity_name}</span>
               </li>
              ))}
            </ul>
          </div>
          <div className="col-6">
            <ul className="amenities-list">
              {props.amenities.slice(3, 6).map((amenity, index) => (
                   <li
                   key={index}
                   className="amenity-item gap-2 d-flex align-items-center"
                 >
                   {/* Assuming amenity_icon should be used here */}
                   {amenity.amenity_icon && (
                     <img
                       src={amenity.amenity_icon}
                       alt={amenity.amenity_name}
                       className="icon property_icons roomaminities mb-2"
                       // Add necessary styles for the icon
                     />
                   )}
                   <span>{amenity.amenity_name}</span>
                 </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="col-12">
          <button className="eg-btn btn btn-searchbar">
            <NavLink className="nav-link" to="">
              Show all Amenities
            </NavLink>
          </button>
        </div> */}
      </div>

            {/* Safety and Hygiene */}
            <div className="my-5">
              <p>Safety and Hygiene</p>
              <div className="row my-3 ">
                <div className="col-6">
                  <div className="d-flex mb-3">
                    <FaBroom size={20} style={{ marginRight: "5px" }} />
                    <p>Daily Cleaning</p>
                  </div>
                  <div className="d-flex">
                    <FaSprayCan size={20} style={{ marginRight: "5px" }} />
                    <p>Disinfections and Sterilizations</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex mb-3">
                    <FaFireExtinguisher
                      size={20}
                      style={{ marginRight: "5px" }}
                    />
                    <p>Fire Extinguishers</p>
                  </div>
                  <div className="d-flex">
                    <FaExclamationTriangle
                      size={20}
                      style={{ marginRight: "5px" }}
                    />
                    <p>Smoke Detectors</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Near by Services */}
            {/* <div className="my-3">
              <p className='mb-3'>Nearby Services</p>
              <div
                id="map-container-google-2"
                class="z-depth-1-half map-container w-100 rounded "
              >
                <img src={props.room_images} className='h-75 w-75' alt="" />
              </div>
              <div className="icon-row text-gold d-flex justify-content-between my-5">
                <div className="px-4 py-3 product_detail_icon_bg rounded">
                  <h5>Mandi House </h5>
                  <p className="text-white">100 meters away</p>
                </div>
                <div className="px-4 py-3 product_detail_icon_bg rounded">
                  <h5> Grill Restro</h5>
                  <p className="text-white">200 meters away</p>
                </div>
                <div className="px-4 py-3 product_detail_icon_bg rounded">
                  <h5> ABCED Mall</h5>
                  <p className="text-white">300 meters away</p>
                </div>
              </div>
            </div> */}

            {/* Venue Rules */}
            <div className="my-3">
              <p> Venue Rules</p>
              <ul>
                {rules.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            </div>

            {/* Reviews */}
            {/* <div className="my-5">
              <p className='mb-3'>
                Reviews <FaStar className="text-gold" /> 5.0
              </p>
              {reviewItems.map((item, index) => (
                <div key={index} className="d-flex gap-3 align-items-center">
                  <div className="col-5">
                  <p>{item.name}</p>
                  </div>
                 <div className="col-lg-6 col-sm-6">
                 <p className="w-75">{renderStarsAndRating(item.rating)}</p>
                 </div>

                  
                </div>
              ))}
            </div> */}

            {/* <div className="row">
              {reviews.map((review) => (
                <div key={review.id} className="col-md-6">
                  <div className="card mb-3 bg-transparent text-white">
                    <div className="card-body ">
                      <div className="d-flex align-items-center mb-3 gap-3">
                        <img
                          src={review.userProfileIcon}
                          alt="User Profile"
                          className="rounded-circle mr-3"
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div className="d-flex flex-column align-items-center ">
                          <h5 className="card-title">{review.userName}</h5>
                          <p>{review.date}</p>
                        </div>
                      </div>
                      <p className="card-text">{review.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
  )
}

export default Product_Description