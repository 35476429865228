import React, { useState, useEffect } from "react";
import Topimages from "./Product_Detail_content/Topimages";
import Other_Rooms from "./Product_Detail_content/Other_Rooms";
import Booking_card from "./Product_Detail_content/Booking_card";
import Product_Description from "./Product_Detail_content/Product_Description";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Cookies from "js-cookie"; // Import js-cookie to retrieve the token
import "react-toastify/dist/ReactToastify.css";

const ProductDetail = (props) => {
  const { propertyId, propertyName, propertyCity, roomId, booked } = useParams();
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [otherRooms, setOtherRooms] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  let { state } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the token from cookies
        const token = Cookies.get("token");

        if (!token) {
          throw new Error("No token available");
        }

        const response = await axios.get(
          `${apiUrl}/v1/property/room/get-room/${propertyId}/${roomId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        );

        if (response.data && response.data.status && response.data.data) {
          const roomsData = response.data.data;
          setRooms(roomsData);
          setLoading(false); // Set loading to false after data fetch
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
    setOtherRooms(state?.availRooms);
  }, [apiUrl, propertyId, roomId, state?.availRooms]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);


  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="container">
      <Topimages
        key={rooms._id}
        roomImages={rooms.room_images}
        room_name={rooms.room_name}
        room_type={rooms.room_type}
        property_city={propertyName}
        property_name={propertyCity}
        room_images={rooms.room_images[0]}
        room_image={rooms.room_images[1]}
        room_imag={rooms.room_images[2]}
        room_ima={rooms.room_images[3]}
        room_im={rooms.room_images[4]}
      />

      {/* Leftside icons */}
      <div>
        <div className="row text-white mx-5 px-5 mobileresponsive">
          {/* Left Description */}
          <Product_Description
            room_description={rooms.room_description}
            bed_size={rooms.bed_size}
            room_number={rooms.room_number}
            max_guest_occupancy={rooms.max_guest_occupancy}
            room_size={rooms.room_size}
            amenities={rooms.room_amenities}
            amenityIcons={rooms.amenityIcons} // Pass the amenityIcons object here
          />
          {/* rightside Bookings */}
          <Booking_card
            roomId={rooms.room_uid}
            propertyId={propertyId}
            room_type={rooms.room_type}
            room_charge={rooms.room_charge}
            is_booked={booked}
            max_guest_occupancy={rooms.max_guest_occupancy}
            room_tax={
              Number(rooms.room_charge) >= 7500
                ? Number(rooms.room_charge * (18 / 100))
                : Number(rooms.room_charge) * (12 / 100)
            }
          />
        </div>
      </div>
      {/* Other Rooms */}
      {/* <div className="">
        {otherRooms.length === 0 ? (
          <p>No other rooms available.</p>
        ) : (
          <Other_Rooms
            otherRooms={otherRooms?.filter((room) => room.room_uid !== roomId)}
            property_name={propertyName}
            property_city={propertyCity}
            is_booked={booked}
          />
        )}
      </div> */}
    </div>
  );
};

export default ProductDetail;
