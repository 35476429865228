import React, { useState, useEffect } from "react";
import { SlCalender } from "react-icons/sl";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRoomUid } from '../../redux/room/roomSlice';
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';

const Booking_card = (props) => {
  const storedData = JSON.parse(Cookies.get("searchData")) || {
    destination: null,
    dateRange: { startDate: null, endDate: null },
    roomCount: 0,
    adults: 0,
    children: 0,
    extraBed:0
  };
  const dispatch=useDispatch();


  const [destination, setDestination] = useState(storedData.destination);
  const [dateRange, setDateRange] = useState(storedData.dateRange);
  const [roomCount, setRoomCount] = useState(storedData.roomCount);
  const [adults, setAdults] = useState(storedData.adults);
  const [children, setChildren] = useState(storedData.children);
  const [bedsCount, setBedsCount] = useState(storedData.extraBed);

useEffect(()=>{
  const updatedData = {
    destination:destination,
    dateRange:dateRange,
    adults:adults,
    children: children,
    roomCount: roomCount,
    extraBed:bedsCount
  };

  Cookies.set("searchData", JSON.stringify(updatedData));
},[adults,children,roomCount,bedsCount])

  const handleAdultsChange = (action) => {
    switch (action) {
      case "add":
        setAdults((prevAdults) => prevAdults + 1);
        break;
      case "sub":
        // Ensure that adults count doesn't go below 0
          if(adults<=0){
            setAdults(0)
          }else{
            setAdults((prevAdults) => prevAdults - 1);

          }
        break;
      default:
        break;
    }
  };
  

  const handleKidsChange = (action) => {
    switch (action) {
      case "add":
        setChildren((prevAdults) => prevAdults + 1);
        break;
      case "sub":
        // Ensure that adults count doesn't go below 0
        if(children<=0){
          setChildren(0)
        }else{
          setChildren((prevAdults) => prevAdults - 1);

        }
        break;
      default:
        break;
    }
  };

  const handleRoomChange = (action) => {
    switch (action) {
      case "add":
        setRoomCount((prevAdults) => prevAdults + 1);
        break;
      case "sub":
        // Ensure that adults count doesn't go below 0
        if(roomCount<=0){
         setRoomCount(0)
        }else{
         setRoomCount((prevAdults) => prevAdults - 1);

        }
        break;
      default:
        break;
    }
  };

  const handleBedsChange = (action) => {
    switch (action) {
      case "add":
        setBedsCount((prevAdults) => prevAdults + 1);
        break;
      case "sub":
        // Ensure that adults count doesn't go below 0
       
        if(bedsCount<=0){
          setBedsCount(0)
         }else{
          setBedsCount((prevAdults) => prevAdults - 1);
 
         }
        break;
      default:
        break;
    }
  };

  return (
    <div className="col-lg-5 ps-3 pe-0 bookingcard   ">
    {/* Right side: Billing Details */}
    <div className="billing-details product_detail_icon_bg rounded px-5 py-3">
      <p className="text-center py-3 fs-4">Booking Details</p>
    <div>
    <div className="d-flex justify-content-between pb-3">
                         <p className=" mb-1">{dateRange.startDate} to {dateRange.endDate}</p> 
                         <SlCalender className="fs-4 " />                        
                        </div>
      <div className="quantity d-flex justify-content-between align-items-center mb-2 cursor-pointer">
        <p className="mb-1">Persons</p>
        <p className="d-flex gap-3 product_detail_increment px-2 py-1 rounded">
          <p className="m-0" id="sub" onClick={()=>handleAdultsChange("sub")}>
            -
          </p>
          <span className="quantity-value">{adults}</span>
          <p className="m-0" id="add" onClick={()=>handleAdultsChange("add")}>
            +
          </p>
        </p>
      </div>

      {/* <div className="quantity d-flex justify-content-between align-items-center mb-2 cursor-pointer">
        <p className="mb-1">Kids</p>
        <p className="d-flex gap-3 product_detail_increment px-2 py-1 rounded-3 ">
          <p className="m-0" onClick={()=>handleKidsChange("sub")}>
            -
          </p>
          <span className="quantity-value">{children}</span>
          <p className="m-0" onClick={()=>handleKidsChange("add")}>
            +
          </p>
        </p>
      </div> */}

      <div className="quantity d-flex justify-content-between align-items-center mb-2 cursor-pointer">
        <p className="mb-1">Rooms</p>
        <p className="d-flex gap-3 product_detail_increment px-2 py-1 rounded ">
          <p className="m-0" onClick={()=>handleRoomChange("sub")} >
            -
          </p>
          <span className="quantity-value">{roomCount}</span>
          <p className="m-0" onClick={()=>handleRoomChange("add")}>
            +
          </p>
        </p>
      </div>
      </div>
      {/* Addons */}
      <div className="my-3">
        {/* <h5>Addons</h5>
        <div className="quantity d-flex justify-content-between align-items-center mb-2 cursor-pointer">
          <p className="mb-1">Extra Beds </p>
          <p className="d-flex gap-3 product_detail_increment px-2 py-1 rounded ">
            <p className="m-0" onClick={()=>handleBedsChange("sub")} >
              -
            </p>
            <span className="quantity-value">{bedsCount}</span>
            <p className="m-0"  onClick={()=>handleBedsChange("add")}>
              +
            </p>
          </p>
        </div> */}
{/* 
        <div className="quantity d-flex justify-content-between align-items-center mb-1">
          <p className="mb-1">Extra Blankets </p>
          <p className="d-flex gap-2 product_detail_increment p-2 rounded-3 ">
            <p className="m-0" onClick={() => handleBlanketsChange(-1)}>
              -
            </p>
            <span className="quantity-value">{blanketsCount}</span>
            <p className="m-0" onClick={() => handleBlanketsChange(1)}>
              +
            </p>
          </p>
        </div>

        <div className="quantity d-flex justify-content-between align-items-center mb-1">
          <p className="mb-1">Extra Pillows </p>
          <p className="d-flex gap-2 product_detail_increment p-2 rounded-3 ">
            <p className="m-0" onClick={() => handlePillowsChange(-1)}>
              -
            </p>
            <span className="quantity-value">{pillowsCount}</span>
            <p className="m-0" onClick={() => handlePillowsChange(1)}>
              +
            </p>
          </p>
        </div> */}
      </div>

      {/* <div className=" orderform rounded">
        <h5 className="text-white py-3 ">Billing Details</h5>
        <div className="mb-3 d-flex ">
          <input
            type="text"
            className="orderforminput bg-transparent"
            id="couponInput"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
          <button onClick={applyCoupon} className="btn btn-searchbar">
            Apply
          </button>
        </div>
        <div className="mb-3 text-white py-3">
          <div className="d-flex justify-content-between mb-2">
            <p>Room price for 4Nights x 4Guests </p> <p>₹7998</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Add ons</p> <p>₹7998</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Taxes & Service Fees</p> <p>₹753</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Hotel GST</p> <p>₹350</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Coupon discount</p> <p>₹1245</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p>Total Amount: </p> <p>₹{totalAmount}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between text-white  fs-4">
          {" "}
          <p>Payable Amount: </p> <p>₹{payableAmount}</p>
        </div>
      </div> */}

      <div className="gap-3 d-flex justify-content-center mt-5">
      <button className="eg-btn btn btn-searchbar ">
                      <NavLink className="nav-link" to="/contact">
                        {/* Book Now */} Contact Us
                      </NavLink>
                    </button>
        {/* {props.is_booked ==="true" ? (
          <button className="btn btn-booked" disabled type="button" onClick={() => {
            // Show tooltip/notification when the "Not Available" button is clicked
         
          }}>
            <NavLink className="nav-link" >Add Room</NavLink>
          </button>
        ) : (
          <button
            className="eg-btn btn btn-searchbar w-50"
            type="button"
            onClick={() => {
            dispatch(setSelectedRoomUid({...props}))
            }}
          >
            <NavLink className="nav-link">Add Room</NavLink>
          </button>
        )} */}
      </div>
    </div>
  </div>
  )
}

export default Booking_card