import React from "react";

const DaypassWorks = () => {
  return (
    <div className="container text-white">
      <div className='d-flex justify-content-start align-items-start '>
        <img src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/line.svg" className="linehome mx-3 mt-4 pt-3" alt="image" />
        <h1 className="display-4">How it works</h1>
        </div>
        <div className="daypasspara px-5 mx-5 mobileresponsive">
            <p>Experience an exclusive day at Hatimi Retreats, reserving unique zones for ziplining, turfs, swimming, farming, and more. Enjoy open common areas, try our exclusive menu, embark on guided walks, and partake in thrilling activities. Contact us for an intimate day of bliss at Hatimi Retreats—where every moment feels like home Limited availability ensures an intimate and personalized experience, so book your spot now!</p>
        </div>

        <div className="row d-flex py-5 justify-content-center">
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1">
            <div className="counter-icon mt-3">
              <img src="assets/bg/contact.svg" style={{width:65,height:65}}alt="" />
            </div>
            <p className="mt-3">Contact Us</p>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1">
            <div className="counter-icon mt-3">
              <img src="assets/bg/bookslot.svg" style={{width:65,height:65}}alt="" />
            </div>
            <p className="mt-3">Book your Slot</p>
          </div>
        </div>

        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1">
            <div className="counter-icon mt-3">
              <img src="assets/bg/checkin.svg" style={{width:65,height:65}}alt="" />
            </div>
            <p className="mt-3">Checkin</p>
          </div>
        </div>

        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-10">
          <div className="counter-single sibling-2 text-center d-flex flex-column hover-border1">
            <div className="counter-icon mt-3">
              <img src="assets/bg/enjoy.svg" style={{width:65,height:65}}alt="" />
            </div>
            <p className="mt-3">Enjoy your day</p>
          </div>
        </div>
        </div>
    </div>
  );
};

export default DaypassWorks;
