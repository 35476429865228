import React, { useState,useEffect } from "react";
import Guest_Details_form from "./Order_detail_Content/Guest_Details_form";
import Bookinginfo from "./Order_detail_Content/Bookinginfo";
import Billing_Details from "./Order_detail_Content/Billing_Details";
import { useSelector } from 'react-redux';
import moment from "moment";
import Cookies from "js-cookie"; 

const Order_detail = () => {
  const [guestDetails, setGuestDetails] = useState({});
  const selectedRoomUid = useSelector((state) => state.room.selectedRoomUid);
  const [destination, setDestination] = useState({});
  const [groupedRooms, setGroupedRooms] = useState([]);


  const handleGuestDetails = (details) => {
    setGuestDetails(details);
  };
 

//   if (loading) {
//     return (
//       <div className="loading-spinner h-100vh w-100 d-flex justify-content-center align-items-center m-5">
//         {/* HashLoader component from react-spinners */}
//         <HashLoader color={"#B08E54"} loading={loading} size={100} />
//       </div>
//     );
//   }
// console.log("lklo",guestDetails)

const propertyData = useSelector((state) => state.room.propertyData);

useEffect(()=>{
  if(destination?.dateRange?.startDate){
    const BookingCheckin = moment(destination?.dateRange?.startDate, "DD MMM YY").format("YYYY-MM-DD");
  const BookingCheckout = moment(destination?.dateRange?.endDate, "DD MMM YY").format("YYYY-MM-DD");
  const numberOfDays = moment(BookingCheckout).diff(moment(BookingCheckin), 'days');
  const gr = selectedRoomUid.map((room,ind)=>{
    const s=room.extra_services?.map((room,ind)=>{
      return {
        ...room,
        "service_days":numberOfDays
      }
    })
    return {
      room_type:room.room_type,
      room_count:room.initialCount,
      single_room_price:room.base_room_charge,
      room_price: room.room_charge ? room.room_charge * numberOfDays : room.base_room_charge * numberOfDays,
      max_guest_occupancy : room.max2_guest_occupancy ? room.max2_guest_occupancy :room.max_guest_occupancy,
      extra_services:s
    }
  })
  setGroupedRooms(gr)
 
  }
},[destination])
useEffect(() => {
  const storedData = Cookies.get("searchData");
  if (storedData) {
    const searchData = JSON.parse(storedData);
    setDestination(searchData);
  }
  
}, []);



  return (
    <>
    <div className="py-5">
    <div className="container mt-4  ">
      <div className="row  mx-5 px-5 mobileresponsive">
            <Bookinginfo
              // key={rooms._id}
              // room_charge={rooms.room_charge}
              property_name={propertyData.property_name}
              property_city={propertyData.property_city}
              //property_city={selectedRoomUid[0].property_city}
              // room_type={rooms.room_type}
              // property_city={propertyName}
              // property_name={propertyCity}
              property_images={propertyData.property_image_url}
            />
       </div>
       </div>
      <div className="container mt-4  ">
      <div className="row  mx-5 px-5 mobileresponsive mobilepaddingmargin">
        
        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mb-3 px-1">
         <Guest_Details_form  onGuestDetails={handleGuestDetails} />
        </div>
       
        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  px-1" >
        <Billing_Details
        // room_name={rooms.room_name}
        property_name={propertyData.property_name}
        room_info={groupedRooms}
        guestDetails={guestDetails}
        property_uid={selectedRoomUid[0]?.propertyId}
      //   room_uid={roomId}
      //   property_city={propertyCity}
      //  room_image_url={rooms.room_images}
        
        />
        </div>
      </div>
    </div>
     
      </div>
    </>
  );
};

export default Order_detail;
